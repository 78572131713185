import type { ReactNode } from "react";
import clsx from "clsx";
import { Breadcrumbs } from "../Breadcrumbs";

interface ArticleLayoutProps {
  children: ReactNode;
  paths: {
    name: string;
    url: string;
  }[];
  size?: "small" | "medium";
}

export function ArticleLayout({ children, paths, size = "medium" }: ArticleLayoutProps) {
  return (
    <div className={clsx("relative", "pt-6", "pb-12")}>
      <Breadcrumbs paths={paths} />
      <div className={clsx("mx-auto", size === "medium" && "max-w-4xl", size === "small" && "max-w-lg")}>
        <div className={clsx("flex", "flex-col", "flex-grow", "bg-white", "pt-12", "pb-16")}>{children}</div>
      </div>
    </div>
  );
}
