import { Link } from "@remix-run/react";
import clsx from "clsx";
import { Fragment } from "react";

interface BreadcrumbsProps {
  paths: {
    name: string;
    url: string;
  }[];
}

export function Breadcrumbs({ paths }: BreadcrumbsProps) {
  return (
    <nav
      x-comp="Breadcrumb"
      className={clsx("relative", "py-2", "pb-4", "border-b", "border-b-blue-200", "md:px-4")}
      aria-label="ページナビゲーション"
    >
      <ol
        className="list-none"
        x-comp="BreadcrumbList"
        aria-label="ページリスト"
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {paths.map((item, i) => {
          return (
            <li
              key={item.name}
              x-comp="BreadcrumbItem"
              className={clsx(
                "font-sans",
                "font-normal",
                "text-xs",
                "inline",
                "overflow-hidden",
                "text-ellipsis",
                i > 0 && ["before:content-['>']", "before:mx-2"]
              )}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              {!(i + 1 === paths.length) ? (
                <Fragment>
                  <Link
                    to={item.url}
                    aria-label={item.name}
                    className={clsx("text-blue-600", "hover:underline", "focus:underline")}
                    itemType="item"
                  >
                    <span itemProp="name">{item.name}</span>
                  </Link>
                  <meta itemProp="position" content={String(i + 1)} />
                </Fragment>
              ) : (
                <Fragment>
                  <span itemProp="name">{item.name}</span>
                  <meta itemProp="position" content={String(i + 1)} />
                </Fragment>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
